<template>

    <div class="o365-header-cell o365-header-cell-container" tabindex="-1" :data-o365-colindex="col.order" :draggable="!col.suppressMovable"
        :style="{width: col.width+col.widthAdjustment+'px', left: dataGridControl.multilineHeader ? '' : col.left+'px'}" :o365-field="col.colId"
        :title="col.headerTitle ?? col.headerName" 
        :class="col.headerCellClass"
        >
        <ErrorRenderer v-if="capturedError"/>
        <template v-else>
            <template v-if="col.resizable">
                <!-- <div v-if="col.flexWidth && col.widthAdjustment" class="o365-flex-sizer" -->
                    <!-- :style="{ -->
                        <!-- 'left': col.width+'px' -->
                    <!-- }"> -->
                    <!-- <i class="bi bi-caret-down-fill"></i> -->
                <!-- </div> -->

                <div class="o365-sizer" :o365-field="col.colId"
                    :class="(useLeftColumnSizers || (col.pinned === 'right' && !dataGridControl.props.disableRightPinnedOffset)) ? 'sizer-left' : 'sizer-right'"></div>
            </template>

            <div class="o365-header-cell-label-container text-truncate" :class="{
                'position-relative': col.colId === 'AutoTreeGroup',
                'multiline-system-header': dataGridControl.multilineHeader && col.field ==='o365_System'
                }">

                <ODropdown v-if="col.menu" placement="bottom-end" @beforeopen="onBeforeDropdownOpen">
                    <template #default="scope">
                        <span class="o365-header-cell-menu mx-1" :ref="scope.target" @click="scope.open">
                            <i class="bi bi-list"></i>
                        </span>
                    </template>
                    <template #dropdown="scope">
                        <div class="card shadow dropdown-menu rounded-0 o365-header-dropdown"
                            style="position: absolute; z-index: 10000; min-width: 200px;" :ref="scope.container">
                            <template v-if="col.bulkUpdate && !isGroupColumn">
                                <button class="dropdown-item" @click="bulkUpdateOpen($event, col); scope.close();" 
                                    :disabled="dataGridControl.selectionControl.selectedRows.length == 0" :title="bulkUpdateSelectedRows?.length == 0 ? 'No rows selected' : ' '"
                                    style="pointer-events: auto;" href="javascript:void(0)">{{$t('Bulk Update')}}</button>
                                <div class="dropdown-divider"></div>
                            </template>

                            <template v-if="col.colId === 'AutoTreeGroup' && deepestLevel">
                                <!-- <TreeifyLevelsDropdown :dataObject="dataGridControl.dataObject" placement="right" :closeFn="scope.close" /> -->
                                <div class="dropdown-divider"></div>
                            </template>

                            <template v-if="!dataGridControl.isTable">
                                <ODropdown placement="right">
                                    <template #default="pinScope">
                                        <button class="dropdown-item" href="javascript:void(0)" :ref="pinScope.target"
                                            @click="pinScope.open">{{$t('Pin Column')}}
                                            <i class="bi bi-caret-right-fill float-end"></i>    
                                        </button>
                                    </template>
                                    <template #dropdown="pinScope">
                                        <div :ref="pinScope.container" class="card shadow dropdown-menu rounder-0">
                                            <button class="dropdown-item"
                                                @click="()=>{ dataGridControl.header.setColumnPin(col, 'left'); scope.close();}">
                                                <i class="bi bi-check2" :style="{'color': col.pinned === 'left' ? null : 'transparent'}"></i>
                                                {{$t('Pin Left')}}
                                            </button>
                                            <button class="dropdown-item"
                                                @click="()=>{ dataGridControl.header.setColumnPin(col, 'right'); scope.close();}">
                                                <i class="bi bi-check2" :style="{'color': col.pinned === 'right' ? null : 'transparent'}"></i>
                                                {{$t('Pin Right')}}
                                            </button>
                                            <button class="dropdown-item"
                                                @click="()=>{ dataGridControl.header.setColumnPin(col); scope.close();}">
                                                <i class="bi bi-check2" :style="{'color': !col.pinned ? null : 'transparent'}"></i>
                                                {{$t('No Pin')}}
                                            </button>
                                        </div>
                                    </template>
                                </ODropdown>
                                <div class="dropdown-divider"></div>
                            </template>

                            <template v-if="col.sortable">
                                <button class="dropdown-item" href="javascript:void(0)"
                                    @click="handleHeaderClick($event, col, 'asc'); scope.close()">{{$t('Sort Ascending')}}</button>
                                <button class="dropdown-item" href="javascript:void(0)"
                                    @click="handleHeaderClick($event, col, 'desc'); scope.close()">{{$t('Sort Descending')}}</button>
                                <div class="dropdown-divider"></div>
                            </template>

                            <template v-if="groupBy">
                                <button class="dropdown-item" href="javascript:void(0)"
                                    @click="()=>{groopByColumn(col); scope.close(); }">{{$t('Group By')}}</button>
                                <div class="dropdown-divider"></div>
                            </template>

            
                            <button class="dropdown-item"
                                @click="()=>{col.hide = true; scope.close(); }">{{$t('Hide Column')}}</button>
                            <div class="dropdown-divider"></div>

                            <template v-if="col.headerMenuSlot">
                                <component :is="col.headerMenuSlot" :close="scope.close" :column="col"></component>
                                <div class="dropdown-divider"></div>
                            </template>
                            <button class="dropdown-item"
                                @click="dataGridControl.resetLayout();scope.close();">{{$t('Reset To Default Layout')}}</button>
                            <button v-if="dataGridControl.dataObject && currentSortOrder != dataGridControl.header.originalSortOrder" class="dropdown-item"
                                @click="dataGridControl.header.resetSortOrder();scope.close();">{{$t('Reset Sort Order')}}</button>
                        </div>
                    </template>
                </ODropdown>

                <template v-if="col._group">
                    <button class="btn btn-link btn-sm" href="javascript:void(0)" :title="$t('Collapse All')" @click="col._group.collapseAll">
                        <i class="bi bi-caret-up-fill"></i>
                    </button>
                    <button class="btn btn-link btn-sm" href="javascript:void(0)" :title="$t('Expand All')" @click="col._group.expandAll">
                        <i class="bi bi-caret-down-fill"></i>
                    </button>
                </template>

                <div v-if="col.field ==='o365_System'" class="system-header d-flex justify-content-center w-100">
        
                    <button v-if="showNewRecordButton" class="btn btn-link btn-sm" :class="{'p-0': dataGridControl.multilineHeader}" @click.stop="createNewRecord"
                        :title="$t('Toggle new records panel')">
                        <i class="bi bi-plus-lg" style="color: black; font-size: large;"></i>
                    </button>
                    <button v-else-if="hasNewRecords" class="btn btn-link btn-sm" :class="{'p-0': dataGridControl.multilineHeader}" @click.stop="closeNewRecords"
                        :title="$t('Toggle new records panel')">
                        <i class="bi bi-x-lg" style="color: black;"></i>
                    </button>
              
                </div>
                <div v-else-if="col.field ==='o365_MultiSelect'" class="multiselect-all">
                    <template v-if="!dataGridControl.props.disableSelectAll">
                        <input v-if="!dataGridControl.selectionControl.selectAllLoading" type="checkbox" class="form-check-input p-2" @click="dataGridControl.selectAll()" v-model="dataGridControl.selectionControl.allRowsSelected" :indeterminate="dataGridControl.selectionControl.isSomeRowsSelected()">
                        <div v-if="dataGridControl.selectionControl?.selectAllLoading" class="d-flex justify-content-center">
                            <div class="spinner-border spinner-border-sm" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </template>
                </div>
                <div v-else class="o365-header-cell-label" @click="handleHeaderClick($event, col)"
                    :class="[{'sortable': col.sortable}, col.headerClass]">
                    <component v-if="col.headerTextSlot" :is="col.headerTextSlot" :column="col"></component>
                    <span v-else class="o365-header-cell-text text-truncate" :class="{'text-wrap': dataGridControl.multilineHeader}">
                        {{ isGroupColumn ? $t('Grouped') : col.headerName ?? $t(col.field) }}
                    </span>
                    <span v-if="col.sortable" v-show="col.sort" class="px-1">
                        <i class="bi" :class="{'bi-arrow-up': col.sort === 'asc', 'bi-arrow-down': col.sort === 'desc'}" ></i>
                        <small>{{col.sortOrder}}</small>
                    </span>
                </div>
            </div>
        </template>
    </div>
</template>

<script setup lang="ts">
import type { confirm } from 'o365-vue-services'
import type DataColumn from './DataGrid.DataColumn.ts';
import type DataGridControl from './DataGridControl.ts';

import { ODropdown } from 'o365-ui-components';
import { useErrorCapture } from 'o365-vue-utils';
import { $t } from 'o365-utils';
import { ref, computed } from 'vue';

function o365_confirm(...args: Parameters<typeof confirm>) {
    return import('o365-vue-services').then(services => services.confirm(...args));
}

export interface IHeaderCellProps {
    col: DataColumn,
    colIndex: number,
    dataGridControl: DataGridControl,
    showNewRecordButton?: boolean,
	hasNewRecords?: boolean,
    closeNewRecordsPanel?: Function,
    createNewRecord?: Function,
    bulkUpdateOpen?: Function,
    groupBy?: boolean,
    useLeftColumnSizers?: boolean,
    bulkUpdateSelectedRows?: any[]
};

const props = defineProps<IHeaderCellProps>();

const [capturedError, ErrorRenderer] = useErrorCapture({
    consoleMessagee: `Error encountered when trying to render column header: ${props.col?.colId}`,
    errorRenderFunctionOptions: {
        uiTitleMessage: 'An error has occured when trying to render this cell'
    }
});

const currentSortOrder = ref('');
function onBeforeDropdownOpen() {
    if (props.dataGridControl.dataObject) {
        const order = props.dataGridControl.dataObject.recordSource.getSortOrder();
        currentSortOrder.value = order ? JSON.stringify(order) : undefined;
    }
}

async function closeNewRecords() {
    const hasChanges = props.hasNewRecords && props.dataGridControl.dataObject.batchData.data.some(x => x.hasChanges);
    if (hasChanges) {
        try {
            await o365_confirm({
                message: $t('Are you sure you want to close the new records panel?'),
                title: $t('Unsaved changes')
            });
        } catch (_) {
            return;
        }
    }
    props.closeNewRecordsPanel();
}

// TODO: move this logic out to the grid control
function handleHeaderClick(e, column, sortDirection = null) {
    if (column.sortable) {
        const vRawDataObject = props.dataGridControl.dataObject;
        if (vRawDataObject) {
            e.preventDefault();
            const sortField = column.sortField ?? column.field;
            const currentField = props.dataGridControl.dataObject?.fields[sortField];
            // const currentField = props.dataGridControl.dataObject.fields.fields.find(field => field.name === sortField);
            let vCurrentSortOrder = vRawDataObject.recordSource.getSortOrder();
            let vSortOrder = {};
            if (!vCurrentSortOrder) vCurrentSortOrder = [];

            switch (currentField.sortDirection) {
                case 'asc':
                    currentField.sortDirection = 'desc';
                    break;
                case 'desc':
                    currentField.sortDirection = 'asc';
                    break;
                default:
                    currentField.sortDirection = column.defaultSortDirection;
            }

            if (sortDirection) {
                currentField.sortDirection = sortDirection;
            }
            vSortOrder[sortField] = currentField.sortDirection;
            if (!e.ctrlKey) {
                vCurrentSortOrder = [vSortOrder];
            } else {
                if (column.sort) {
                    vCurrentSortOrder.forEach((item, index) => {
                        const vTmp = Object.keys(item);
                        if (vTmp[0] === sortField) {
                            vCurrentSortOrder[index] = vSortOrder;
                        }
                    });
                } else {
                    vCurrentSortOrder.push(vSortOrder);
                }

            }

            vRawDataObject.recordSource.setSortOrder(vCurrentSortOrder);

            const columns = props.dataGridControl.dataColumns.columns;
            columns.filter(x => x.sort).forEach(col => {
                col.sortOrder = null;
                col.sort = null;
            });

            vCurrentSortOrder.forEach((ord, index) => {
                const vTmp = Object.keys(ord);
                const vCols = columns.filter(x => x.field === vTmp[0] || x.sortField === vTmp[0]);
                if (vCols.length > 0) {
                    vCols.forEach(vCol => {
                        if (vCurrentSortOrder.length > 1) {
                            vCol.sortOrder = index + 1;
                        }
                        vCol.sort = ord[vTmp[0]];
                    });
                }
            })

            /*
            if (props.dataGridControl.dataObject.layoutManager) {
                props.dataGridControl.dataObject.layoutManager.trackSortByChange();
            }
            */

            vRawDataObject.load();
        } else {
             e.preventDefault();
            const gridCOntrol = props.dataGridControl;
            const col = column;

            const orgSort = col.sort;
            switch (col.sort) {
                case 'asc':
                    col.sort = 'desc';
                    break;
                case 'desc':
                default:
                    col.sort = 'asc';
            }

            if (e.ctrlKey) {
                if (orgSort == null) {
                    const sortingCols = gridCOntrol.dataColumns.columns.filter(x => {
                        if (x.sort != null) {
                            if (x.sortOrder == null) { x.sortOrder = 1; }
                            return true;
                        } else {
                            return false;
                        }
                    });
                    col.sortOrder = sortingCols.length;
                }
            } else {
                gridCOntrol.dataColumns.columns.forEach(x => x.sortOrder = null);
                gridCOntrol.dataColumns.columns.forEach(x => {
                    if (x.colId !== col.colId) {
                        x.sort = null;
                    }
                });
            }

            gridCOntrol.utils.applySort();
        }
    }
}

const isGroupColumn = computed(() => {
    return props.col._isGroupColumn
});

function groopByColumn(column) {
    window.requestAnimationFrame(() => {
        const groupBy = props.dataGridControl.dataObject.grouping.groupBy; 
        props.dataGridControl.dataObject.grouping.groupBy = [...groupBy, column.field];
        if (column) {
            column._hide = true;
        }
        props.dataGridControl.dataObject.load().then(() => {
            updateGroupColumnHeader();
        });
    });
}

function updateGroupColumnHeader() {
    const groupColumn = props.dataGirdControl.dataColumns.getColumn('o365_Group');
    if (groupColumn) {
        const groupBy = props.dataGridControl.dataObject.grouping.groupBy; 
        groupColumn.headerName = groupBy.map(field => getColumn(field).headerName).join(' / ');
        groupColumn.headerTitle = groupBy.map(field => getColumn(field).headerName).join(' / ');
    }
}

const deepestLevel = computed(() => props.dataGridControl.dataObject.treeify?.deepestLevel ?? 0);
</script>